import React from "react";

export default function Placeholder3() {
  return (
    <>
      <div className="Body">
        <header>
          <span className="text-decoration-underline">Placeholder3</span>
        </header>
      </div>
    </>
  );
}
