import "./Navbar.css";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import catIcon from "../../images/cat.jpg";

export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
      <div className="container-fluid d-flex align-items-center">
        <Link to="/" className="navbar-brand d-block">
          <img
            src={catIcon}
            alt="Home"
            style={{ maxWidth: "5rem", height: "2.5rem" }}
          />
        </Link>
        <button
          className="navbar-toggler ms-auto"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={menuOpen ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <div className="navbar-nav-container">
            <ul className="navbar-nav">
              <li className="nav-item mx-2">
                <NavLink
                  to="/placeholder"
                  className={({ isActive }) =>
                    isActive ? "nav-link text-active" : "nav-link"
                  }
                >
                  Placeholder
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink
                  to="/placeholder1"
                  className={({ isActive }) =>
                    isActive ? "nav-link text-active" : "nav-link"
                  }
                >
                  Placeholder1
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink
                  to="/placeholder2"
                  className={({ isActive }) =>
                    isActive ? "nav-link text-active" : "nav-link"
                  }
                >
                  Placeholder2
                </NavLink>
              </li>
              <li className="nav-item mx-2">
                <NavLink
                  to="/placeholder3"
                  className={({ isActive }) =>
                    isActive ? "nav-link text-active" : "nav-link"
                  }
                >
                  Placeholder3
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
