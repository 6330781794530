import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";
import Home from "./pages/Home.jsx";
import NavBar from "./components/NavBar/Navbar.jsx";
import NotFound from "./pages/NotFound.js";
import Placeholder from "./pages/Placeholder.jsx";
import Placeholder1 from "./pages/Placeholder1.jsx";
import Placeholder2 from "./pages/Placeholder2.jsx";
import Placeholder3 from "./pages/Placeholder3.jsx";

function Routes() {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/placeholder" element={<Placeholder />} />
        <Route exact path="/placeholder1" element={<Placeholder1 />} />
        <Route exact path="/placeholder2" element={<Placeholder2 />} />
        <Route exact path="/placeholder3" element={<Placeholder3 />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/notes/:noteId"> //example route to open tab of component using an id
          <Home />
        </Route> */}
      </Switch>
    </Router>
  );
}

export default Routes;
